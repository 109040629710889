import React, {useState, useEffect} from 'react';
import {Modal, Button} from 'antd';

const Footer = props => {
  const [state, setState] = useState ({
    loading: false,
    visible: false,
  });

  const showModal = () => {
    setState ({
      ...state,
      visible: true,
    });
    props.setVisible (true);
  };
  useEffect (
    () => {
      setState ({
        ...state,
        visible: props.visible,
      });
    },
    [props.visible]
  );

  const handleOk = () => {
    setState ({...state, loading: true});

    setTimeout (() => {
      setState ({...state, loading: false, visible: false});
    }, 3000);
  };

  const handleCancel = () => {
    props.setVisible (false);
    setState ({...state, visible: false});
  };
  // componentDidUpdate(prevProps){
  //   if(props.visible !== prevProps.visible ){
  //     setState({ visible: false });
  //   }
  // }

  const {visible, loading} = state;
  return (
    <div className="footer-modal">
      {props.custom?<div onClick={showModal} className={props.className} style={{cursor:'pointer',width:props.customWidth,textAlign:props.equipmentcheckin&&'end'}}> {props.Button}</div>:<Button style={{background:props.buttonCustomColor,borderColor:props.buttonCustomColor,width:props.buttonCustomWidth}} type={props.buttonColor?props.buttonColor:"primary"} onClick={showModal}>
        {props.Button}
      </Button>}
      <Modal
        width={props.fullWidth?props.fullWidth:'70%'}
        visible={visible}
        title={<h3>{props.title}</h3>}
        onOk={handleOk}
        maskClosable={false}
        onCancel={handleCancel}
        footer={[
          // <Button key="back" onClick={handleCancel}>
          //   Return
          // </Button>,
          props.submit,
          // <Button
          //   key="submit"
          //   type="primary"
          //   loading={loading}
          //   onClick={handleOk}
          // >
          //   Submit
          // </Button>
        ]}
      >
        {props.children}
      </Modal>
    </div>
  );
};

export default Footer;
